import React, { useState } from "react";
import axios from "axios";

var api_endpoint = "https://christophsaffer.com/api/submit-form.php"
var input_css = "ml-8 p-2 m-3 border-slate-300 border shadow-sm"


function Form() {
    const [formData, setFormData] = useState({
        name: "",
        email: ""
    });

    const [submit, setSubmit] = useState(false);

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    console.log(formData)

    const handleSubmit = async (event) => {
        event.preventDefault();

        setFormData({name: "", email: ""})

        try {
            const response = await axios.post(api_endpoint, formData).then(setSubmit(true));
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <form className="grid place-items-center" onSubmit={handleSubmit}>
            <table>
                <tbody>
                    <tr>
                        <th>Name: </th>
                        <th>
                            <input className={input_css}
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            /></th>
                    </tr>
                    <tr>
                        <th>E-Mail: </th>
                        <th>
                            <input className={input_css}
                                type="email"
                                id="email"
                                name="email" value={formData.email}
                                onChange={handleChange}
                                required
                            /></th>
                    </tr>
                </tbody>
            </table>
            <br /> <div className="grid place-items-center">
                {!submit && <button className="bg-blue-300 round p-4 rounded-xl -mt-2 hover:bg-blue-200 border-blue-300"
                    type="submit">Subscribe</button>}

                {submit && <div className="bg-green-400 round p-4 rounded-xl -mt-2">Thank you!</div>}

            </div>

        </form>
    );
}

export default Form;
